.SimilarProperties {
	display: none;
	visibility: hidden;

	&--visible {
		display: block;
		visibility: visible;
	}

	&-title {
		margin-bottom: 30px;
	}

	&-container {
		display: block;
	}

	.SimilarProperty {
		padding-bottom: 30px;
		margin-bottom: 30px;
		display: none;
		border-bottom: 1px solid $separatorLight;

		&--visible {
			display: block;
		}

		&--no-border {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		&-image {
			padding-left: 0;
			padding-right: 0;

			@include viewport(medium) {
				padding-right: $gutter_medium;

				@include rtl {
					padding-left: $gutter_medium;
				}
			}

			@include viewport(large) {
				padding-right: $gutter_large;

				@include rtl {
					padding-left: $gutter_large;
				}
			}

			@include viewport(xlarge) {
				padding-right: $gutter_xlarge;

				@include rtl {
					padding-left: $gutter_xlarge;
				}
			}
		}

		&-wrapper {
			padding-left: 0;
			padding-right: 0;
			position: relative;
		}

		&-propertyName {
			margin-top: 20px;
			margin-bottom: 10px;

			@include viewport(medium) {
				display: inline-block;
				margin-top: 0;
				max-width: 57%;
			}

			@include viewport(large) {
				max-width: 60%;
				margin-bottom: 5px;
			}

			// TODO: It's must refactor typography styles when VD updates the master sketch.
			&--ty {
				@include sansThin;
				font-size: rem(28, 16);
				letter-spacing: em(0.64, 28);
				line-height: em(33, 28);

				@include viewport(large) {
					font-size: rem(24, 16);
					letter-spacing: em(0.55, 24);
					line-height: em(33, 24);
				}
			}
		}

		&-regionTitle {
			margin: 0;

			@include viewport(medium) {
				position: absolute;
				display: inline-block;
				top: 0;
				right: 0;

				@include rtl {
					right: auto;
					left: 0;
				}
			}
		}

		&-allAvailablePropertiesLabel {
			margin-top: 0;

			@include viewport(medium) {
				position: absolute;
				right: 0;
				top: 15px;
				margin: 0;

				@include rtl {
					right: auto;
					left: 0;
				}
			}
		}

		&-label {
			display: inline-block;
			font-style: italic;
		}

		&-description {
			margin-bottom: 0;
			margin-top: 14px;

			@include viewport(medium) {
				margin: 0;
				display: block;
				max-width: 58%;
			}

			@include viewport(large) {
				max-width: 60%;
				font-size: rem(15, 16);
			}
		}

		&-cta {
			display: inline-block;
			margin-top: 20px;

			@include viewport(medium) {
				position: absolute;
				text-align: right;
				margin-top: 0;
				right: 0;
				top: 70px;

				@include rtl {
					right: auto;
					left: 0;
					text-align: left;
				}
			}

			@include viewport(large) {
				top: 58px;
			}

			.CTA {
				position: relative;
				display: block;

				&::after {
					display: inline-block;
					padding-left: 12px;
				}

				@include rtl {
					&::after {
						padding-left: 0;
						padding-right: 12px;
					}
				}
			}
		}
	}
}
