.SearchInput {
	margin-top: 50px;

	&-wrapper {
		position: relative;

		&::after {
			@include icon('icon-search');
			position: absolute;
			font-size: 25px;
			left: 1px;
			bottom: 54%;
			transform: translateY(54%);
			color: $black;

			@include viewport(medium) {
				font-size: 35px;
			}
		}

		@include rtl {
			&::after {
				right: 1px;
				left: auto;
			}
		}
	}

	&-btnClear {
		position: absolute;
		display: block;
		border: none;
		background-color: transparent;
		transform: translateY(50%);
		right: 1px;
		padding: 0;
		bottom: 50%;
		width: 30px;
		height: 100%;

		@include rtl {
			left: 1px;
			right: auto;
		}

		@include viewport(medium) {
			width: auto;
		}

		&--icon {
			&::before {
				@include icon('icon-close');
				text-align: right;
				display: block;
				font-size: 14px;
				color: $gray2;

				@include viewport(medium) {
					font-size: 20px;
				}
			}

			@include rtl {
				&::before {
					text-align: left;
				}
			}
		}
	}

	&-label {
		margin-bottom: 24px;
		display: block;

		&--ty {
			@include viewport(medium) {
				@include typography(h3);
			}
		}
	}

	&-field {
		display: block;
		width: 100%;
		height: 60px;
		border-radius: 0;
		border: none;
		border-bottom: 1px solid $gray1;
		padding: 0.5em 1.5em;
		padding-right: 1.13em;
		color: $black;
		background: $white;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		appearance: none;
		cursor: pointer;

		&::placeholder {
			line-height: normal;
			color: $gray2;
		}

		&::-ms-clear {
			display: none;
		}

		@include viewport(medium) {
			height: 70px;
			padding-left: 1.45em;
			padding-right: 1em;
		}

		@include rtl {
			padding-right: 1.5em;
			padding-left: 1.13em;

			@include viewport(medium) {
				padding-right: 1.54em;
				padding-left: 1em;
			}
		}

		// TODO: It's must refactor typography styles when VD updates the master sketch.
		&--ty {
			@include sansThin;
			font-size: rem(28, 16);
			letter-spacing: em(0.64, 28);
			line-height: em(33, 28);

			@include viewport(medium) {
				font-size: rem(36, 16);
				letter-spacing: em(0.82, 36);
				line-height: em(33, 36);
			}
		}
	}

	&-results {
		display: none;
		visibility: hidden;
	}

	&-onResults {
		position: absolute;
		display: block;
		visibility: visible;
		overflow: hidden;
		width: 1px;
		height: 1px;
		margin: -1px;
		border: 0;
		padding: 0;
		clip: rect(0, 0, 0, 0);
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		white-space: nowrap;
	}

	&-noResults {
		display: none;
		margin-top: 20px;

		@include typography(b2);

		@include viewport(large) {
			margin-top: 30px;
		}

		&--visible {
			display: block;
		}
	}

	&-searchInstructions {
		display: none;
	}
}
