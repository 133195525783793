.RentalsHero {
	&--dark {
		background-color: $black;

		.RentalsHero-Search {
			margin-bottom: 0;
		}
	}

	&-Search {
		align-items: center;
		background: white;
		// TODO: Standardize and save this shadow in variables
		box-shadow: 0 2px 4px 0 rgba(119, 119, 119, 0.25);
		color: $black;
		display: flex;
		margin: 0 0 4px;
		padding: 25px 15px;
		text-decoration: none;

		@include viewport(medium) {
			padding: 35px 25px 35px 35px;
		}

		@include viewport(large) {
			margin: 0 auto 4px;
			position: relative;
			top: -52px;
			z-index: 1;
			width: 930px;
		}

		.icon-search {
			font-size: 24px;
			margin-right: 15px;

			@include rtl {
				margin-left: 15px;
				margin-right: 0;
			}

			@include viewport(medium) {
				font-size: 30px;
				margin-right: 20px;
			}
		}

		.icon-arrow_right {
			font-size: 14px;
			margin-left: 5px;

			@include rtl {
				&::before {
					@include iconName('icon-arrow_left');
				}

				margin-left: 0;
				margin-right: 5px;

				@include viewport(large) {
					margin-left: 0;
					margin-right: 20px;
				}
			}

			@include viewport(large) {
				margin-left: 20px;
			}
		}

		&-labelText {
			flex-grow: 1;

			@include sansThin;
			font-size: rem(24, 16);
			letter-spacing: em(0.55, 24);
			line-height: em(33, 24);

			@include viewport(medium) {
				font-size: rem(28, 16);
				letter-spacing: em(0.64, 28);
				line-height: em(33, 28);
			}
		}

		&-searchText {
			display: none;

			@include viewport(medium) {
				display: block;
			}
		}
	}

	.Hero-headingContainer {
		@include viewport(medium) {
			bottom: 100px;
		}

		@include viewport(large) {
			bottom: 122px;
		}
	}
}
