.HomepageHero {
	.Hero {
		&-headingContainer {
			bottom: 40px;
			@include viewport(medium) {
				width: 450px;
				bottom: 75px;
			}

			@include viewport(large) {
				bottom: 100px;
				width: 600px;
			}

			@include viewport(xlarge) {
				bottom: 150px;
				width: 930px;
			}
		}

		.BackgroundImage::after {
			height: 55%;
			@include viewport(medium) {
				height: 70%;
			}
			@include viewport(large) {
				height: 60%;
			}
		}

		.Heading {
			&-title {
				&::after {
					content: none;
				}
			}
		}
	}
}
