.ProductSelector {
	padding: 30px 15px 0;
	margin-bottom: 80px;

	@include viewport(medium) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@include viewport(large) {
		display: flex;
		justify-content: space-between;
	}

	@include viewport(xlarge) {
		width: $containerWidth-xl;
		margin: 0 auto 80px;
		padding-left: 0;
		padding-right: 0;
	}
}

.ProductCard {
	text-align: center;

	&:last-child {
		margin-top: 40px;

		@include viewport(medium) {
			margin-top: 30px;
		}

		@include viewport(large) {
			margin: 0;
		}
	}

	@include viewport(large) {
		width: calc(50% - 15px);
	}

	&-textBox {
		bottom: 0;
		padding: 0 10px 6px;
		position: absolute;
		width: 100%;
		z-index: 2;

		@include viewport(medium) {
			padding: 0 20px 16px;
		}

		@include viewport(large) {
			padding: 0 30px 27px;
		}

		@include viewport(xlarge) {
			padding-bottom: 24px;
		}
	}

	&-heading {
		color: $white;
		margin: 0 0 4px;

		@include viewport(medium) {
			margin-bottom: 17px;
		}
	}

	&-cta {
		&::after {
			float: none;
		}

		@include rtl {
			&::after {
				float: none;
			}
		}
	}

	&-image {
		position: relative;

		&::after {
			@include gradient2transparent($height: 60%);
			bottom: 0;
			content: '';
			display: block;
			position: absolute;
		}
	}

	&-image-cta {
		display: inline-block;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
		margin: 0;
		border: 0;
		padding-top: 100% * (254 / 345);

		@include viewport(medium) {
			padding-top: 100% * (440 / 708);
		}

		@include viewport(large) {
			padding-top: 100% * (560 / 673);
		}

		@include viewport(xlarge) {
			padding-top: 100% * (764 / 915);
		}
	}
}
