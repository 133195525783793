.AllRentalDestinations {
	&-heading {
		margin-top: 70px;
		margin-bottom: 36px;
	}

	&-headingTitle {
		@include viewport(medium) {
			@include typography(h3);
		}
	}

	// Accordion View
	&-accordion {
		&--hidden {
			display: none;
		}

		@include viewport(large) {
			display: none;
		}
	}

	&-item {
		padding: 27px 0 20px;

		&-region {
			margin-top: 20px;
		}
	}

	&-title {
		margin-bottom: 0;
	}

	&-linkContainer {
		margin-bottom: 14px;
	}

	// Table View
	&-table {
		display: none;
		margin-bottom: -36px;

		@include viewport(large) {
			display: block;
		}

		&--hidden {
			@include viewport(large) {
				display: none;
			}
		}

		&-items {
			display: flex;
			flex-wrap: wrap;
		}

		&-item {
			width: 25%;
			margin-bottom: 36px;

			@include viewport(xlarge) {
				width: 20%;
			}
		}

		&-regionTitle {
			margin-bottom: 18px;
		}

		&-properties {
			padding-right: 30px;

			@include rtl {
				padding-right: 0;
				padding-left: 30px;
			}

			@include viewport(xlarge) {
				padding-right: 60px;

				@include rtl {
					padding-right: 0;
					padding-left: 60px;
				}
			}
		}

		&-propertyName {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		&-cta {
			display: inline-block;
			line-height: 15px;
		}
	}
}
