.RentalProperties {
	margin-top: 20px;

	@include viewport(medium) {
		margin-top: 30px;
	}

	@include viewport(large) {
		margin-top: 40px;
	}

	&-property {
		display: none;
		visibility: hidden;

		&--visible {
			display: block;
			visibility: visible;
		}

		@include viewport(medium) {
			padding-bottom: 30px;
			margin-bottom: 30px;
			border-bottom: 1px solid $separatorLight;

			&:last-child {
				margin-bottom: 60px;
			}
		}

		@include viewport(large) {
			padding-bottom: 40px;
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 70px;
			}
		}
	}

	&-image {
		margin-bottom: 20px;
		padding-left: 0;
		padding-right: 0;

		@include viewport(medium) {
			margin-bottom: 0;
			padding-right: $gutter_medium;

			@include rtl {
				padding-left: $gutter_medium;
			}
		}

		@include viewport(large) {
			padding-right: $gutter_large;

			@include rtl {
				padding-left: $gutter_large;
			}
		}

		@include viewport(xlarge) {
			padding-right: $gutter_xlarge;

			@include rtl {
				padding-left: $gutter_xlarge;
			}
		}
	}

	&-propertyContent {
		position: relative;
		padding-bottom: 20px;
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 30px;
		border-bottom: 1px solid $separatorLight;

		.RentalProperties-property:last-child & {
			margin-bottom: 50px;
		}

		@include viewport(medium) {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;

			.RentalProperties-property:last-child & {
				margin-bottom: 0;
			}
		}
	}

	&-propertyName {
		margin-top: 0;
		margin-bottom: 10px;

		// TODO: Replace this with the typography class once
		// design updates the master sketch
		&--ty {
			@include sansThin();
			font-size: rem(28, 16);
			letter-spacing: em(0.64, 28);
			line-height: em(33, 28);
		}

		@include viewport(medium) {
			display: inline-block;
			width: 50%;
		}

		@include viewport(large) {
			margin-bottom: 15px;
		}
	}

	&-region {
		@include viewport(medium) {
			position: absolute;
			display: inline-block;
			width: 50%;
			text-align: right;
			top: 0;
			right: 0;

			@include rtl {
				text-align: left;
				right: auto;
				left: 0;
			}
		}
	}

	&-propertyRegion {
		margin-top: 0;
		margin-bottom: 6px;

		@include viewport(large) {
			font-size: rem(12, 16);
		}
	}

	&-propertyLabel {
		margin-top: 0;
		margin-bottom: 13px;

		// TODO: Replace this with the typography class once
		// design updates the master sketch
		&--ty {
			@include serifItalic();
			font-size: rem(15, 16);
			letter-spacing: em(0.5, 28);
			line-height: em(25, 28);

			@include viewport(large) {
				font-size: rem(22, 16);
				line-height: em(31, 28);
			}
		}
	}

	&-label {
		display: inline-block;
	}

	&-propertyDescription {
		margin-top: 0;
		margin-bottom: 30px;

		@include viewport(medium) {
			display: inline-block;
			width: 60%;
			margin-bottom: 0;
		}
	}

	&-propertyCta {
		.CTA {
			width: 100%;
		}

		@include viewport(medium) {
			position: absolute;
			display: inline-block;
			top: 45px;
			right: 0;

			.CTA {
				width: auto;
			}

			@include rtl {
				right: auto;
				left: 0;
			}
		}

		@include viewport(large) {
			top: 80px;
		}
	}
}
