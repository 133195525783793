.Newsletter-Checkbox {
	margin-bottom: 40px;

	@include viewport(xlarge) {
		margin-bottom: 70px;
	}
}

.LegalDisclaimer {
	margin-top: 40px;

	@include viewport(xlarge) {
		margin-top: 70px;
	}

	.NoWrap {
		white-space: nowrap;
	}
}

.ContactForm {
	&-heading {
		&-title {
			text-align: center;
			margin-top: 0;
			margin-bottom: 25px;

			@include viewport(medium) {
				margin-bottom: 30px;
			}

			@include viewport(large) {
				margin-bottom: 35px;
			}

			@include viewport(xlarge) {
				margin-bottom: 40px;
			}
		}
	}

	&-form {
		.Intro > p {
			margin-top: 0;
		}
	}

	padding-top: 45px;

	@include viewport(medium) {
		padding-top: 55px;
	}

	@include viewport(xlarge) {
		padding-top: 65px;
	}
}
