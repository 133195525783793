@use 'sass:math';

.ResidencesEmbeddedMap {
	position: relative;

	&-summaryTab {
		padding-bottom: 15px;

		@include viewport(medium) {
			padding-bottom: 25px;
		}
	}

	&-skipMap {
		height: 25px;
		margin-left: 10px;
		@include rtl {
			margin-right: 10px;
			margin-left: 0;
		}
	}

	&-controls {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;
		gap: 0;
		width: calc(100% - 30px);
		margin: 15px;

		@include viewport(large) {
			top: 26px;
			left: 32px;
			gap: 10px;
			margin: 0;
			width: auto;
		}

		&-viewToggle {
			&Container {
				display: flex;
				flex-direction: row;

				width: 100%;
				justify-content: center;

				@include viewport(large) {
					width: auto;
				}

				button {
					background-color: $white;
					color: $black;
					display: flex;
					height: 40px;
					padding: 8px 15px;
					align-items: center;
					gap: 10px;
					border: 0.5px solid var(--color-neutral-300, #d8d8d8);

					flex-basis: 50%;
					justify-content: center;

					@include viewport(large) {
						flex-basis: auto;
					}

					&.is-active {
						background-color: $black;
						border-color: $black;
						color: $white;
					}
				}
			}
			&-icon {
				display: flex;
				justify-content: center;
				font-size: 20px;
			}
			&-text {
				font-feature-settings: 'clig' off, 'liga' off;

				/* Detail/D4 */
				font-family: 'Helvetica Neue', sans-serif;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 18px; /* 150% */
				letter-spacing: 1px;
			}
		}

		&-forSale {
			margin: 0;
			padding: 0;
			align-items: flex-end;
			color: $black;
			&Container {
				align-self: stretch;
				padding: 15px;
				border: 0.5px solid var(--color-neutral-300, #d8d8d8);
				height: 50px;
				width: 100%;
				background: var(--surface-surface-translucent, rgba(255, 255, 255, 0.7));
				backdrop-filter: blur(15px);

				@include viewport(large) {
					width: auto;
					background: $white;
					padding: 8px 15px;
					backdrop-filter: none;
					height: 40px;
				}
			}

			& .Checkbox {
				&-label {
					line-height: 18px;
				}
				&-decoration {
					margin-top: 0;
				}
			}
		}
	}

	&-content {
		position: relative;
		width: 100%;
		margin: auto;

		@include viewport(large) {
			max-width: $containerWidth-xl;
			height: 100%;
		}
	}

	&-map {
		height: 650px;
		margin: auto;

		@include viewport(large) {
			height: 100%;
			aspect-ratio: 1440 / 784;
			min-height: 784px;
		}
	}

	&-list {
		background: $gray4;
		padding: 122px 15px 15px;

		@include viewport(large) {
			padding: 122px 120px 106px;
		}

		&--noToggle {
			padding-top: 82px;

			@include viewport(large) {
				padding-top: 122px;
			}
		}

		&ViewAccordion {
			padding: 30px 15px;
			background-color: $white;

			@include viewport(large) {
				padding: 70px 100px;
			}

			&.ComponentAccordion .Accordion--expansion-chevron .Accordion-item {
				border-top: none;
				padding-top: 0;
				padding-bottom: 25px;

				@include viewport(large) {
					padding-bottom: 35px;
				}

				& .Accordion-item-button {
					border-bottom: 1px solid var(--color-neutral-300, #d8d8d8);

					@include viewport(large) {
						padding-bottom: 15px;
					}
				}

				&--collapsed .Accordion-item-button {
					padding-bottom: 15px;
				}

				& .Accordion-item-region {
					padding: 0;
					padding-top: 18px;

					@include viewport(large) {
						padding-top: 0;
					}
				}

				& .Accordion-item-title {
					color: $black;
				}

				& .LinksList-links {
					padding-left: 0;
					padding-right: 0;

					@include viewport(large) {
						padding-left: 15px;
						padding-right: 15px;
					}
				}
			}
		}
	}

	&-cluster {
		position: absolute;
		cursor: pointer;
		width: 42px;
		height: 42px;
		line-height: 40px;
		letter-spacing: 0;
		text-align: center;
		background: $black;
		border-radius: 50%;
		border: 1px solid $white;
		color: $white;
	}

	.MapCard {
		position: fixed;
		bottom: 0;
		z-index: 10000;
		display: flex;
		justify-content: center;
		direction: ltr;
		transition: visibility 0.3s, opacity 0.3s;
		border-radius: 15px 15px 0px 0px;
		width: 100%;
		height: 100%;
		background-color: transparent;

		&.is-hidden {
			visibility: hidden;
			opacity: 0;
		}

		@include viewport(large) {
			max-width: 400px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 50px;
			bottom: unset;
			box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12),
				0px 5px 5px 0px rgba(0, 0, 0, 0.2);
			border-radius: unset;
			border: none;
			max-height: 90%;
			height: auto;
			z-index: 1;
		}

		&-container {
			background-color: $white;
			color: $black;
			pointer-events: initial;
			outline: 0;
			overflow: hidden;
			text-align: center;
			border-radius: 15px 15px 0px 0px;
			height: auto;
			max-height: 90%;
			bottom: 0;
			position: absolute;

			@include viewport(large) {
				border-radius: unset;
				position: relative;
				width: 100%;
				height: 100%;
			}
		}

		&-mobile-background {
			width: 100%;
			height: 100%;
			background-color: rgba($black, 0.7);
			z-index: -1;
			@include viewport(large) {
				display: none;
			}
		}

		&-closeButton {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 2;
		}

		&-imageContainer {
			// To keep the aspect ratio even if the image hasn't been loaded
			padding-top: math.div(240, 420) * 100%;
			position: relative;
			transition: opacity 0.3s, visibility 0.3s;
			width: 100%;

			&.is-hidden {
				opacity: 0;
				visibility: hidden;
			}

			@include viewport(large) {
				max-width: 400px;
			}
		}

		&-image {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
		}

		&-titleContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 20px 50px 15px;
			border-radius: 15px 15px 0px 0px;
			border: 1px solid var(--color-neutral-300, #d8d8d8);
			border-bottom: none;

			@include viewport(large) {
				gap: 5px;
				padding: 40px 25px;
				border-radius: unset;
				border: none;
			}
		}

		&-title {
			margin: 0;

			@include viewport(large) {
				@include typography(h3);
			}
		}

		&-subtitle {
			@include viewport(large) {
				@include typography(h3);
				font-weight: 400;
			}
		}

		&-description {
			margin: 0;
		}

		&-cta {
			width: 100%;
		}

		&-copyContainer {
			width: 100%;
			padding: 20px 15px 30px;
			display: flex;
			flex-direction: column;
			gap: 30px;
			border: 1px solid var(--color-neutral-300, #d8d8d8);
			border-top: none;

			@include viewport(large) {
				padding: 30px 32.5px 50px;
				border: none;
			}
		}
	}
}
