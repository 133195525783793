.ContactUsResidences {
	.StandardHero {
		&-title {
			padding-bottom: 50px;

			@include viewport(medium) {
				padding-bottom: 80px;
			}

			@include viewport(large) {
				padding-bottom: 100px;
			}

			@include viewport(xlarge) {
				padding-bottom: 140px;
			}
		}
	}

	.Heading {
		&-eyebrow {
			@include typography(h4);
		}
	}

	.ContactTable {
		@include component-spacing;

		&-title {
			@include typography(c4);
			margin-top: 0;
			margin-bottom: 20px;

			@include viewport(medium) {
				margin-bottom: 30px;
			}
		}
	}
}
